/// Regular Numbers
/**
 * @summary Whole positive number that allowed 0, 1 and 99999.
 * @description Also allowed 00, 000 and 0999.
 */
export const integerNumberRegex = /^[0-9\b]+$/; // Is id different? /^[0-9]+$/;
// @summary Whole Positive
// This number is usually called unsigned integer, but you can also call it a positive non-fractional number, include zero.
// This includes numbers like 0, 1 and 99999. But not 077!
// The Regular Expression that covers this validation is:
export const wholePositiveNumberRegex = /^(0|[1-9]\d*)$/;
// Whole Positive and Negative
// This number is usually called signed integer, but you can also call it a non-fractional number. This includes numbers
// like 0, 1, 99999, -99999, and -1.
// -0 is restricted by (?<!-0) block.
// The Regular Expression that covers this validation is:
export const wholePositiveAndNegativeNumberRegex = /^-?(0|[1-9]\d*)(?<!-0)$/;
/// Fractional Numbers
// Old
export const floatNumberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
// Positive
// This number is usually called unsigned float or unsigned double, but you can also call it a positive fractional number, include zero.
// This includes numbers like 0, 1, 0.0, 0.1, 1.0, 99999.000001, 5.10.
// The Regular Expression that covers this validation is:
export const floatPositiveNumberRegex = /^(0|[1-9]\d*)(\.\d+)?$/;
// That is for numbers like .1, .0 and .00651 (same as 0.1, 0.0 and 0.00651 respectively) are also valid fractional numbers,
// so here is a regex that is additionally supports this format:
export const floatPositiveNumberStartDotRegex = /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/;
// Negative and Positive
// This number is usually called signed float or signed double, but you can also call it a fractional number.
// This includes numbers like 0, 1, 0.0, 0.1, 1.0, 99999.000001, 5.10, -1, -0.0, -0.1, -99999.000001, 5.10.
// The Regular Expression that covers this validation is:
export const floatPositiveAndNegativeNumberRegex = /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/;
// with -0
export const floatPositiveAndNegativeWithMinusZeroNumberRegex = /^-?(0|[1-9]\d*)(\.\d+)?$/;
// To support also the invisible zero representations, like .1, -.1, use the following regex:
export const floatPositiveAndNegativeStartDotNumberRegex = /^-?(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/;
/** Positive float numbers like 0, 1, 0.0, 0.1, 1.0, 99999.000001, 5.10, -1, -0.0, -0.1, -99999.000001, 5.10.
 * Last dot is allowed here! That's why it can be used in NumericField input restriction.
 */
// export const floatNumberWithLastDotAllowed = /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
export const floatPositiveNumberLowRestriction = /^(0|[1-9]\d*)((\.\d+)|(\.))?$/;
