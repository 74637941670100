export const periodDateRangeDeserializer = (initialValue) => (value) => {
    let parsed;
    try {
        parsed = JSON.parse(value);
        return {
            period: parsed.period,
            periodDateFrom: new Date(parsed.periodDateFrom),
            periodDateTo: new Date(parsed.periodDateTo),
        };
    }
    catch (error) {
        console.error('Error parsing JSON:', error);
        return initialValue; // Return initialValue if parsing fails
    }
};
