import { stockBaseApi } from 'shared/modules/stock/business';
export const stockApi = stockBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        addStockItem: builder.mutation({
            query: (stockItem) => ({
                method: 'POST',
                url: 'stock/createStockItem',
                body: stockItem,
            }),
            // invalidatesTags: (result, error, stockItem) => [
            // 	// { type: 'Customers', id: supplierId }, // TODO: to delete ? is is mistake?
            // 	{ type: 'Stock', id: stockItem.stockId },
            // 	//{ type: 'SupplierPriceList', id: priceList.pricelistId },
            // ],
            invalidatesTags: (result, error, stockItem) => ['Stock', 'StockList'],
        }),
        // TODO: to delete later
        // // If we want Lazy, then we should use queryFn instead of query and stockItemId as undefined to "clear" result. Workaround since useLazyQuery cant skip.
        // getStockItemCostHistory: builder.query<IStockBalanceCostHistoryItem[], { stockItemId?: number }>({
        // 	query: ({ stockItemId }) => ({
        // 		method: 'GET',
        // 		url: `stock/stockBalanceHistory/${stockItemId}`,
        // 	}),
        // 	// // @ts-ignore
        // 	// queryFn: async (arg, api, extraOptions, baseQuery) => {
        // 	// 	if (!arg.stockItemId) return { data: undefined };
        // 	// 	const result = await baseQuery({
        // 	// 		method: 'GET',
        // 	// 		url: `stock/stockBalanceHistory/${arg.stockItemId}`,
        // 	// 	});
        // 	// 	if (result.error) return { error: result.error, data: null };
        // 	// 	const data = result.data as (Omit<IStockItemCostHistoryItem, 'created'> & { created: string })[];
        // 	// 	return {
        // 	// 		data: data.map((historyItem) => ({
        // 	// 			...historyItem,
        // 	// 			created: new Date(historyItem.created),
        // 	// 		})),
        // 	// 	};
        // 	// },
        // 	// transformResponse not working with queryFn.
        // 	transformResponse: (response: (Omit<IStockBalanceCostHistoryItem, 'created'> & { created: string })[]) =>
        // 		response.map((historyItem) => ({
        // 			...historyItem,
        // 			created: new Date(historyItem.created),
        // 		})),
        // 	providesTags: ['Stock'],
        // }),
        updateStockBalanceMargin: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, marginPercent }) => ({ stockBalanceId, marginPercent })),
            }),
            // invalidatesTags: (result, error, stockItem) => [
            // 	// { type: 'Customers', id: supplierId }, // TODO: to delete ? is is mistake?
            // 	{ type: 'Stock', id: stockItem.stockId },
            // 	//{ type: 'SupplierPriceList', id: priceList.pricelistId },
            // ],
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        updateStockBalanceCost: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, primeCost }) => ({ stockBalanceId, primeCost })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        updateStockItemQuantity: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockItem',
                body: items.map(({ stockItemId, count }) => ({ stockItemId, count })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        // relocateStockItem: builder.mutation<void, { stockItemId: number; quantity: number; cellName: string }[]>({
        relocateStockItem: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stock/relocate',
                body,
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }, { type: 'StockList' }],
        }),
        // // TODO: may be (or not) move to settings? It is used there also.
        // getStockDictionary: builder.query<IStockLookupItem[], void>({
        // 	query: () => {
        // 		return {
        // 			method: 'GET',
        // 			url: 'stock/stocksdictionary',
        // 		};
        // 	},
        // 	transformResponse: (response: any) =>
        // 		mapKeyValuePairObjectToArray({
        // 			kvpObject: response,
        // 			keyName: propertyOf<IStockLookupItem>('stockId'),
        // 			valueName: propertyOf<IStockLookupItem>('stockName'),
        // 		}) as IStockLookupItem[],
        // }),
        /** Activates or deactivates stock balances */
        activateStockBalance: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, isActive }) => ({ stockBalanceId, isActive })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        deleteStockBalance: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stock/deleteStockBalance',
                body,
            }),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        uploadPricesFile: builder.mutation({
            query: ({ file, stockId }) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('stockId', JSON.stringify(stockId));
                return {
                    method: 'POST',
                    url: 'stockDocument/updatePrice',
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: [{ type: 'Stock' }],
        }),
    }),
    overrideExisting: false,
});
export const { useGetStockListQuery, useGetStockInfoQuery, useGetStockBalanceQuery, useExportStockToExcelMutation, useAddStockItemMutation, useGetStockItemCostHistoryQuery, useUpdateStockBalanceMarginMutation, useUpdateStockBalanceCostMutation, useUpdateStockItemQuantityMutation, useRelocateStockItemMutation, useGetStockDictionaryQuery, useActivateStockBalanceMutation, useDeleteStockBalanceMutation, useUploadPricesFileMutation, } = stockApi;
