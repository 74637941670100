import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
const infiniteScrollDefaultPageSize = 100;
const calculateMaxPages = (total, size) => {
    return Math.ceil(total / size);
};
const isValidNotEmptyArray = (array) => {
    return !!(array && array?.length && array?.length > 0);
};
// export interface IListQueryResponse {
// 	items: any[];
// 	total: number;
// 	page: number;
// 	size: number;
// }
//interface IUrlParams {
// interface IGetDataListQueryArguments {
// 	offset: number;
// 	limit: number;
// 	search?: string;
// 	//[x: string]: any;
// }
const useInfiniteScroll = (
//useGetDataListQuery: UseQuery<any>,
useGetDataListQuery, 
//useGetItemQuery: UseQuery<any>,
useGetItemQuery, { size = infiniteScrollDefaultPageSize, search = '', ...queryParameters }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [combinedData, setCombinedData] = useState([]);
    const isLast = useRef(false);
    // Temporary workaround to force refetch on useGetDataListQuery call.
    const updatedItemId = useRef(undefined);
    const args = {
        offset: (currentPage - 1) * size,
        limit: size,
        ...queryParameters,
    };
    if (search !== undefined && search.length)
        args.search = search.trim();
    const query = useGetDataListQuery(args);
    const fetchData = useMemo(() => query.data || [], [query.data]);
    // TODO: to delete later if following useGetItemQuery still works properly with skipToken
    // // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    // const itemQuery = useGetItemQuery(updatedItemId.current?.itemIdValue!, { skip: updatedItemId.current === undefined });
    const itemQuery = useGetItemQuery(updatedItemId.current?.itemIdValue ?? skipToken);
    const itemQueryData = useMemo(() => itemQuery.data, [itemQuery.data]);
    useEffect(() => {
        if (!itemQueryData)
            return;
        //console.log('on itemQueryData change, itemQueryData: ', itemQueryData);
        updateCombinedDataWith(itemQueryData);
    }, [itemQueryData]);
    const updateCombinedDataWith = useCallback((updatedItem) => {
        if (!updatedItemId.current)
            return;
        const { itemIdName, itemIdValue } = updatedItemId.current;
        // @ts-ignore: Unreachable code error
        const index = combinedData.findIndex((el) => el[itemIdName] === itemIdValue);
        if (index === -1) {
            // New item was created: add new one in the beginning and remove old one to the end to keep existed page size the same.
            setCombinedData((items) => [updatedItem, ...items.slice(0, -1)]);
        }
        else {
            // Update existed one.
            setCombinedData((items) => [...items.slice(0, index), updatedItem, ...items.slice(index + 1)]);
        }
        // console.log('in updateCombinedDataWith, index: ', index);
        // console.log('in updateCombinedDataWith, updatedItem: ', updatedItem);
        // TODO: to return if list refetch used.
        ///updatedItemId.current = undefined;
    }, [combinedData, setCombinedData]);
    //console.log('args', args);
    //console.log('queryParameters', queryParameters);
    //console.log('currentPage', currentPage);
    // const {
    // 	items: fetchData = [],
    // 	page: remotePage = 1,
    // 	total: remoteTotal = 0,
    // 	size: remoteSize = 10,
    //} = (queryResponse?.data as IListQueryResponse) || {};
    // TODO: actually not used now it's just not to break following code.
    //const remotePage = currentPage;
    useEffect(() => {
        setCombinedData([]);
        setCurrentPage(1);
    }, [size, search]);
    useEffect(() => {
        //console.log('fetchData: ', fetchData);
        // // First working variant.
        // if (isValidNotEmptyArray(fetchData)) {
        // 	if (currentPage === 1) setCombinedData(fetchData);
        // 	//else if (currentPage === remotePage) {
        // 	else {
        // 		setCombinedData((previousData) => [...previousData, ...fetchData]);
        // 	}
        // }
        //refetchNeeded.current = false;
        isLast.current = fetchData.length < size;
        if (currentPage === 1) {
            setCombinedData(fetchData);
            return;
        }
        if (isValidNotEmptyArray(fetchData)) {
            //else if (currentPage === remotePage) {
            setCombinedData((previousData) => [...previousData, ...fetchData]);
        }
        // else {
        // 	isLast.current = true;
        // }
    }, [fetchData]);
    // TODO: uncomment and use when we'll have 'page' 'total' and 'size' params in IListQueryResponse.
    // const maxPages = useMemo<number>(() => {
    // 	return calculateMaxPages(remoteTotal, remoteSize);
    // }, [remoteTotal, remoteSize]);
    const refresh = useCallback((item) => {
        // If no parameters passed Just refresh and set first page.
        if (!item) {
            refetchFirstPage();
            return;
        }
        // This 'if' case is necessary only for set customer in isActive state when updatedItemId.current is undefined.
        // useGetItemQuery just doesn't work without a reason. It works on updateCustomer, but doesn't work on update iActive state.
        if (updatedItemId.current === undefined) {
            updatedItemId.current = item;
            // Just force rerender with setState.
            setCurrentPage(currentPage);
        }
        updatedItemId.current = item;
    }, [combinedData]);
    // TODO: to delete if not used
    const refetchFirstPage = useCallback(() => {
        setCombinedData([]);
        setCurrentPage(1);
        query.refetch();
    }, [currentPage]);
    const readMore = () => {
        // if (currentPage < maxPages && currentPage === remotePage) {
        // 	setCurrentPage((page) => page + 1);
        // }
        //if (currentPage === remotePage) {
        setCurrentPage((page) => page + 1);
        //}
    };
    return {
        data: combinedData,
        localPage: currentPage,
        readMore,
        refresh,
        isLast: isLast.current,
        isLoading: query?.isLoading,
        isFetching: query?.isFetching,
        isError: query?.isError,
        isSuccess: query?.isSuccess,
        error: query?.error,
    };
};
export { useInfiniteScroll };
