import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    customerAddDialog: {
        open: false,
    },
    customerEditDialog: {
        open: false,
        customerId: undefined,
    },
    customerBalanceDialog: {
        open: false,
        customerId: undefined,
        customerName: undefined,
        orderId: undefined,
    },
    deliveryAddressEditDialog: {
        open: false,
        deliveryAddress: undefined,
    },
    legalEditDialog: {
        open: false,
        legal: undefined,
    },
    emailEditDialog: {
        open: false,
        email: undefined,
    },
    priceListMarkupEditPopover: {
        open: false,
        markupEditData: {
            top: 0,
            left: 0,
            logo: '',
            customerId: 0,
            priceListId: 0,
            markup: undefined,
        },
    },
};
export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        addCustomerOpen: (state) => {
            state.customerAddDialog.open = true;
        },
        addCustomerClose: (state) => {
            state.customerAddDialog.open = false;
        },
        editCustomerOpen: (state, action) => {
            state.customerEditDialog.open = true;
            state.customerEditDialog.customerId = action.payload.customerId;
        },
        editCustomerClose: (state) => {
            state.customerEditDialog.open = false;
            state.customerEditDialog.customerId = undefined;
        },
        customerBalanceOpen: (state, action) => {
            state.customerBalanceDialog.open = true;
            state.customerBalanceDialog = { ...action.payload, open: true };
        },
        customerBalanceClose: (state) => {
            state.customerBalanceDialog.open = false;
            state.customerBalanceDialog.customerId = undefined;
            state.customerBalanceDialog.customerName = undefined;
            state.customerBalanceDialog.orderId = undefined;
        },
        editDeliveryAddressOpen: (state, action) => {
            state.deliveryAddressEditDialog.open = true;
            state.deliveryAddressEditDialog.deliveryAddress = action.payload.deliveryAddress;
        },
        editDeliveryAddressClose: (state) => {
            state.deliveryAddressEditDialog.open = false;
            state.deliveryAddressEditDialog.deliveryAddress = undefined;
        },
        editLegalOpen: (state, action) => {
            state.legalEditDialog.open = true;
            state.legalEditDialog.legal = action.payload.legal;
        },
        editLegalClose: (state) => {
            state.legalEditDialog.open = false;
            state.legalEditDialog.legal = undefined;
        },
        editEmailOpen: (state, action) => {
            state.emailEditDialog.open = true;
            state.emailEditDialog.email = action.payload.email;
        },
        editEmailClose: (state) => {
            state.emailEditDialog.open = false;
            state.emailEditDialog.email = undefined;
        },
        editPriceListMarkupOpen: (state, action) => {
            state.priceListMarkupEditPopover.open = true;
            state.priceListMarkupEditPopover.markupEditData = action.payload;
        },
        editPriceListMarkupClose: (state) => {
            state.priceListMarkupEditPopover.open = false;
            state.priceListMarkupEditPopover.markupEditData = {
                top: 0,
                left: 0,
                logo: '',
                customerId: 0,
                priceListId: 0,
                markup: undefined,
            };
        },
    },
});
export const { actions: customerActions, reducer: customerReducer } = customerSlice;
