import { styled } from '@mui/material';
export const DetailsBlockContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});
export const DetailsBlock = styled('div')({
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '4rem',
    paddingBottom: '4rem',
    columnGap: '2rem',
});
export const DetailsDescription = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});
export const DetailsDescriptionHead = styled('p')({
    lineHeight: '1.75rem',
    fontWeight: 600,
    fontSize: '1rem',
    marginTop: 0,
    marginBottom: '.25rem',
});
