import { useAuthStore } from './business';
import { useMemo } from 'react';
const tokenExpired = (user) => {
    const unixNow = Math.floor(Date.now() / 1000);
    // 10 seconds before token expired.
    return !user || unixNow + 10 > user.expiration;
};
export const useAuth = () => {
    const { token: tokenFromStore, user: userFromStore, userClaims: userClaimsFromStore } = useAuthStore((state) => state);
    const updateByLocalStorage = useAuthStore((state) => state.updateByLocalStorage);
    let token = tokenFromStore;
    let user = userFromStore;
    let userClaims = userClaimsFromStore;
    // If page is refreshed with F5 button press all redux state is lost and we have to refresh it with values from localStorage.
    if (!token || !user) {
        token = localStorage.getItem('token');
        const userDataJson = localStorage.getItem('userData');
        const currentUser = JSON.parse(userDataJson || 'null');
        if (token && currentUser) {
            updateByLocalStorage(token, currentUser);
            // updateByLocalStorage flow is still pending, so should be updated here to not have undefined token from still not updated store.
            const { claims, ...other } = currentUser;
            user = other;
            userClaims = claims || [];
        }
    }
    // Block layout to be rendered if token still exists, but is already expired.
    if (token && tokenExpired(user))
        token = undefined;
    // If somehow we've got broken value from local storage.
    if (token === null || token === 'null' || token === 'undefined')
        token = undefined;
    const isAdmin = user?.roles.includes('Admin');
    return useMemo(() => ({
        token,
        currentUser: {
            claims: userClaims,
            isAdmin,
            /** Customer manager, customer head manager or admin. */
            isCustomerManager: isAdmin || userClaims.includes('CustomerManager') || userClaims.includes('CustomerHeadManager'),
            /** Supplier manager, supplier head manager or admin. */
            isSupplierManager: isAdmin || userClaims.includes('SupplierManager') || userClaims.includes('SupplierHeadManager'),
            /** Shows whether the current user has access to any of specified claims. */
            hasAccessTo: (...claims) => {
                if (isAdmin)
                    return true;
                if (userClaims.some((userClaim) => claims.includes(userClaim)))
                    return true;
                // To avoid this checks CustomerHeadManager and SupplierHeadManager roles should also have 'CustomerManager' and 'SupplierManager'
                // claims besides 'CustomerHeadManager' and 'SupplierHeadManager' claims.
                if (userClaims.includes('CustomerHeadManager') && claims.includes('CustomerManager'))
                    return true;
                if (userClaims.includes('SupplierHeadManager') && claims.includes('SupplierManager'))
                    return true;
                return false;
            },
        },
    }), [token, userClaims, isAdmin]);
};
