import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from 'core/ui/TextField';
import { floatPositiveNumberLowRestriction, integerNumberRegex } from 'core/utils/validation';
import React from 'react';
const NumericField = React.forwardRef(({ variant = 'whole', onChange, ...props }, ref) => {
    return (_jsx(TextField, { ref: ref, ...props, onChange: (event) => {
            if (event.target.value === '') {
                onChange?.(null);
                return;
            }
            switch (variant) {
                case 'whole': {
                    if (integerNumberRegex.test(event.target.value)) {
                        onChange?.(+event.target.value);
                    }
                    break;
                }
                case 'floatPositive': {
                    // console.log('event.target.value:', event.target.value);
                    if (floatPositiveNumberLowRestriction.test(event.target.value)) {
                        // console.log('+event.target.value:', +event.target.value);
                        // console.log('parseFloat(event.target.value):', Number.parseFloat(event.target.value));
                        onChange?.(event.target.value);
                    }
                    break;
                }
            }
        } }));
});
NumericField.displayName = 'NumericField';
export { NumericField };
