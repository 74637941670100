import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Select } from 'core/ui';
import { Chip, ListItemIcon, ListItemText, MenuItem, Skeleton, styled } from '@mui/material';
import { useController } from 'react-hook-form';
/**
 * Construct Select by data, provided whether with {@link items} or {@link getQuery} property,
 * and uses {@link keyProperty} as key property name, {@link valueProperty} as value property name
 * and {@link iconProperty} as icon property name,
 * @param multiple optional
 * @param items required
 * @param getQuery required
 * @param keyProperty required
 * @param valueProperty required
 * @param iconProperty optional
 * @returns
 */
const CustomSelect = ({ multiple, keyProperty, valueProperty, iconProperty, items, getQuery, ...controllerProps }) => {
    const { field: { ref, ...field }, fieldState: { invalid, error }, } = useController(controllerProps);
    // const queryResult = getQuery?.({}); with search variant
    const queryResult = getQuery?.();
    // console.log('queryResult.data', queryResult?.data);
    // console.log('items', items);
    // console.log('queryResult?.data ?? items', queryResult?.data ?? items);
    // console.log('field.value', field.value);
    const options = queryResult?.data ?? items;
    if (!options)
        return _jsx(Skeleton, { variant: "rounded", width: "100%", height: 36 });
    return (_jsx(Select, { ...field, multiple: multiple, 
        // @ts-expect-error Mui type bug: 'selected' is unknown instead of proper infer.
        renderValue: multiple
            ? (selected) => (_jsx(ChipContainer, { children: selected.map((value) => (_jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: options.find((item) => item[keyProperty] === value)?.[valueProperty] }, value))) }))
            : undefined, sx: multiple ? sxSelectMultiple : iconProperty ? sxSelectWithIcon : {}, children: options.map((item) => (_jsx(MenuItem, { value: item[keyProperty], sx: sxMenuItem, children: iconProperty ? (_jsxs(_Fragment, { children: [_jsx(ListItemIcon, { sx: sxListItemIcon, children: _jsx(Avatar, { alt: item[valueProperty], image: item[iconProperty], sx: sxAvatar }) }), _jsx(ListItemText, { primary: item[valueProperty], sx: sxListItemText })] })) : item[valueProperty] }, item[keyProperty]))) }));
};
const ChipContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.375rem',
});
const sxSelectMultiple = {
    '.MuiSelect-select.MuiSelect-multiple': {
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
        '> span': { lineHeight: 1.5 },
    },
};
// TODO: extract this styles to 'Select' (GlobalParts Select) component!
const sxSelectWithIcon = { '.MuiSelect-select': { display: 'flex', alignItems: 'center' } };
const sxAvatar = { width: '1.25rem', height: '1.25rem', fontSize: '.75rem' };
const sxListItemIcon = { minWidth: 0, maxHeight: '1.25rem' };
const sxListItemText = { margin: '0 0 0 .75rem', lineHeight: '1.5rem' };
const sxMenuItem = { padding: '.5rem .75rem', '.MuiListItemIcon-root': { minWidth: 'unset' } };
export { CustomSelect };
