// TODO: later (mb extract the file to admin folder? like in other places?)
export const serverInputSearchDebounceDelay = 500;
export const localInputSearchDebounceDelay = 500;
/**
 * Represents the maximum length of an error message, responded from remote api, to be shown in error component.
 *
 * If a message is larger than the limit it is to be truncated to the limit.
 */
export const maxServerErrorMessageLengthLimit = 400;
/**
 *  Maximum allowed price list file size (in bytes) to be uploaded.
 *
 *  50 Mb limit.
 */
export const piceListFileMaxSizeToUpload = 50 * 1024 * 1024;
/**
 * Represents the number of minutes after which a deactivated user will be automatically activated back.
 *
 * Used in *Settings* -> *Identity* -> *UserDeactivationDialog*
 */
export const defaultAutoActivateUserMinutesCount = 60;
/**
 * User avatar maximum allowed file size in bytes.
 */
export const userAvatarMaxFileSizeToUpload = 1024 * 1024;
/**
 * Represents default value for Stock margin on Stock creation.
 *
 * Used when creating a new Stock in Settings.
 */
export const defaultStockMarginPercent = 30;
export const englishAlphabet = 'abcdefghijklmnopqrstuvwxyz';
