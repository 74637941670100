import { getFileExtensionPattern } from 'core/utils/validation';
/**
 * Truncates string to the specified amount of symbols adding ellipsis to the end
 */
export function truncate(value, limit) {
    if (!value)
        return value;
    return value.length > limit ? value.slice(0, limit - 1) + ' ...' : value;
}
/**
 * Gets an extension from {@link fileName}
 * @param {string} fileName
 * @returns
 */
export const getFileExtension = (fileName) => getFileExtensionPattern.exec(fileName)?.[1];
