import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { localInputSearchDebounceDelay, serverInputSearchDebounceDelay } from 'admin/helpers/constants';
import { TextFieldUncontrolled } from 'core/ui';
import { t } from 'shared/translations';
import { useDebounce } from 'core/hooks';
/**
 * TextField intended for server or local search with specified character input debounce.
 * @param debounce input debounce.
 * @param onChange on search input change handler.
 */
const SearchTextField = ({ debounce = 'server', placeholder, defaultValue, onChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debounceDelay = debounce === 'server' ? serverInputSearchDebounceDelay : debounce === 'local' ? localInputSearchDebounceDelay : debounce;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
    const onSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value.trim());
    }, []);
    useEffect(() => {
        onChange(debouncedSearchTerm.toLocaleLowerCase());
    }, [onChange, debouncedSearchTerm]);
    return _jsx(TextFieldUncontrolled, { placeholder: placeholder || t('search.label'), defaultValue: defaultValue, onChange: onSearchChange });
};
export { SearchTextField };
