import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    orderStatusChangeDialog: {
        open: false,
        orderStatus: undefined,
    },
    orderStatusHistoryDialog: {
        open: false,
        portionInfo: undefined,
    },
    orderCommentEditDialog: {
        open: false,
        orderId: 0,
        comment: undefined,
    },
    orderFilterDrawer: {
        open: false,
    },
    pageSize: 50,
    searchTerm: '',
    // TODO probably we do not need extra duplicated storage here in state, since it is already stored in local(session)Storage.
    // It is needed for <Orders /> to get orderFilter values from <OrderFilterDrawer />
    orderFilter: {
        // Must be undefined since filter is taken from localStorage and has it's own initial values.
        // Otherwise duplicated request occurs.
        startDate: undefined,
        endDate: undefined,
        startPrice: 0,
        endPrice: 0,
        startQty: 0,
        endQty: 0,
        statuses: [],
        customers: [],
        suppliers: [],
        customerManagers: [],
        supplierManagers: [],
        logos: [],
        orders: [],
        brands: [],
        articles: [],
    },
};
export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        orderStatusChangeOpen: (state, action) => {
            state.orderStatusChangeDialog.open = true;
            state.orderStatusChangeDialog.orderStatus = action.payload;
        },
        orderStatusChangeClose: (state) => {
            state.orderStatusChangeDialog.open = false;
            state.orderStatusChangeDialog.orderStatus = undefined;
        },
        orderStatusHistoryOpen: (state, action) => {
            state.orderStatusHistoryDialog.open = true;
            state.orderStatusHistoryDialog.portionInfo = action.payload;
        },
        orderStatusHistoryClose: (state) => {
            state.orderStatusHistoryDialog.open = false;
            state.orderStatusHistoryDialog.portionInfo = undefined;
        },
        editOrderCommentOpen: (state, action) => {
            state.orderCommentEditDialog = { ...action.payload, open: true };
        },
        editOrderCommentClose: (state) => {
            state.orderCommentEditDialog.open = false;
            state.orderCommentEditDialog.orderId = 0;
            state.orderCommentEditDialog.comment = undefined;
        },
        setOrderFilter: (state, action) => {
            state.orderFilter = action.payload;
        },
        openOrderFilterDrawer: (state) => {
            state.orderFilterDrawer.open = true;
        },
        closeOrderFilterDrawer: (state) => {
            state.orderFilterDrawer.open = false;
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
});
export const { actions: orderActions } = orderSlice;
export const orderReducer = orderSlice.reducer;
