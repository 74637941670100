import { formatDate } from 'core/utils/datetime';
export const getOrdersRequestPayload = ({ page, rowsPerPage, search, filter }) => {
    return {
        page,
        rowsPerPage,
        search,
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        startPrice: filter?.startPrice,
        endPrice: filter?.endPrice,
        startQty: filter?.startQty,
        endQty: filter?.endQty,
        statuses: filter?.statuses,
        customers: filter?.customers,
        suppliers: filter?.suppliers,
        customerManagers: filter?.customerManagers,
        supplierManagers: filter?.supplierManagers,
        logos: filter?.logos,
        orders: filter?.orders,
        brands: filter?.brands,
        articles: filter?.articles,
        sortColumn: null,
        sortDirectionAsc: false,
    };
};
/**
 * Formats date value to string in a way the order filter can accept
 *
 * Btw:
 * Converts **utc** date as string "2024-06-01T21:00:00.000Z" to **local** "2024-06-02T00:00:00" as a result.
 *
 * @param {Date} date
 * @returns
 */
export const formatDateForOrdersFilter = (date) => formatDate(date, `yyyy-MM-dd'T'HH:mm:ss`);
