import { UploadMethod } from 'admin/modules/suppliers/models';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    supplierAddDialog: {
        open: false,
    },
    supplierEditDialog: {
        open: false,
        supplierId: undefined,
    },
    supplierPriceListEditDialog: {
        open: false,
        supplierId: undefined,
        supplierPriceListId: undefined,
        supplierIsStock: undefined,
        fileState: {
            success: true,
            method: UploadMethod.Manual,
        },
        newFileUploaded: false,
    },
    unrecognizedBrandsDialog: {
        open: false,
        priceListLogo: undefined,
        unrecognizedBrands: undefined,
        refreshUnsavedBrands: undefined,
        refreshUnsavedBrandsIsFetching: false,
    },
};
const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        openSupplierEditDialog: (state, action) => {
            state.supplierEditDialog.open = true;
            state.supplierEditDialog.supplierId = action.payload.supplierId;
        },
        closeSupplierEditDialog: (state) => {
            state.supplierEditDialog.open = false;
            state.supplierEditDialog.supplierId = undefined;
        },
        addSupplierOpen: (state) => {
            state.supplierAddDialog.open = true;
        },
        addSupplierClose: (state) => {
            state.supplierAddDialog.open = false;
        },
        openSupplierPriceListEditDialog: (state, action) => {
            state.supplierPriceListEditDialog.open = true;
            state.supplierPriceListEditDialog.supplierId = action.payload.supplierId;
            state.supplierPriceListEditDialog.supplierPriceListId = action.payload.supplierPriceListId;
            state.supplierPriceListEditDialog.supplierIsStock = action.payload.supplierIsStock;
        },
        closeSupplierPriceListEditDialog: (state) => {
            state.supplierPriceListEditDialog = initialState.supplierPriceListEditDialog;
        },
        setSupplierPriceListEditDialogFileState: (state, action) => {
            state.supplierPriceListEditDialog.fileState.success = action.payload.success ?? true;
            state.supplierPriceListEditDialog.fileState.method = action.payload.method;
            if (action.payload.success === true) {
                state.supplierPriceListEditDialog.newFileUploaded = true;
            }
        },
        openUnrecognizedBrandsDialog: (state, action) => {
            state.unrecognizedBrandsDialog = { ...action.payload, open: true };
        },
        closeUnrecognizedBrandsDialog: (state) => {
            state.unrecognizedBrandsDialog.open = false;
            state.unrecognizedBrandsDialog.priceListLogo = undefined;
            state.unrecognizedBrandsDialog.refreshUnsavedBrands = undefined;
            // Must be not an 'undefined', but an empty array not to remove dialog from DOM on close.
            state.unrecognizedBrandsDialog.unrecognizedBrands = [];
        },
    },
});
export const { actions: supplierActions, reducer: supplierReducer } = supplierSlice;
