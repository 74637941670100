import React, { useCallback, useEffect, useRef, useState } from 'react';
export const infiniteScrollDefaultPageSize = 100;
const calculateMaxPages = (total, size) => {
    return Math.ceil(total / size);
};
const isValidNotEmptyArray = (array) => {
    return !!(array && array?.length && array?.length > 0);
};
// const useKetexInfiniteScroll = <T extends { isNext: boolean; page: number;}, K extends keyof T>(
const useKetexInfiniteScroll = ({ useGetDataListQuery, 
/**
 * Handles the case when query returns not an array of entities but object with some props (like 'isNext', 'page' and 'entities1', 'entities2' )
 * and one of this props is target prop with the array of entities.
 */
dataListProp, dataListQueryParams, //: { size = infiniteScrollDefaultPageSize, search = '', ...queryParameters };
dataListQuerySkip, useGetItemQuery, }) => {
    const { size = infiniteScrollDefaultPageSize, search = '', ...queryParameters } = dataListQueryParams;
    // TODO: UPDATES NOT WORKING WHEY queryParamters changed. Wait new backend then fix.
    // useEffect(() => {
    // 	setCombinedData([]);
    // 	setCurrentPage(1);
    // 	console.log('queryPARAMS: ', queryParameters);
    // }, [queryParameters]);
    // >(
    // 	//useGetDataListQuery: UseQuery<any>,
    // 	// useGetDataListQuery: UseQuery<QueryDefinition<IGetDataListQueryArguments & { [x: string]: any }, any, string, T, string>>,
    // 	useGetDataListQuery: UseQuery<QueryDefinition<GetDataListQueryArgsType, any, string, T, string>>,
    // 	dataListProp: keyof T & string,
    // 	{ size = infiniteScrollDefaultPageSize, search = '', ...queryParameters },
    // 	useGetItemQuery?: UseQuery<any>
    // ) => {
    const [currentPage, setCurrentPage] = useState(1);
    /////const currentPage = useRef(1);
    const [combinedData, setCombinedData] = useState([]);
    ////const combinedData = useRef<DataListItemType[]>([]);
    const isLast = useRef(false);
    const updatedItemId = useRef(undefined);
    const args = {
        offset: (currentPage - 1) * size,
        /////offset: (currentPage.current - 1) * size,
        limit: size,
        ...queryParameters,
    };
    if (search !== undefined && search.length)
        args.search = search.trim();
    // const query = useGetDataListQuery(args as GetDataListQueryArgsType, { skip: });
    const query = useGetDataListQuery(args, { skip: dataListQuerySkip });
    // const fullData = React.useMemo(() => (query.data as T) || {}, [query.data]);
    const fullData = React.useMemo(() => query.data || {}, [query.data]);
    // const fetchData = React.useMemo(() => ((query.data as T)?.[dataListProp] as DataListItemType[]) || [], [query.data]);
    const fetchData = React.useMemo(() => query.data?.[dataListProp] || [], [query.data]);
    //const itemQuery = useGetItemQuery?.(updatedItemId.current?.itemIdValue, { skip: updatedItemId.current === undefined });
    const itemQuery = useGetItemQuery?.(updatedItemId.current?.itemIdValue, {
        skip: updatedItemId.current === undefined,
        //refetchOnMountOrArgChange: true,
    });
    const itemQueryData = React.useMemo(() => itemQuery?.data, [itemQuery?.data]);
    //console.log('itemQueryData: ', itemQueryData);
    //console.log('updatedItemId.current: ', updatedItemId.current);
    // console.log('combinedData: ', combinedData);
    // console.log('fullData: ', fullData);
    useEffect(() => {
        if (!itemQueryData)
            return;
        console.log('on itemQueryData change, itemQueryData: ', itemQueryData);
        updateCombinedDataWith(itemQueryData);
    }, [itemQueryData]);
    const updateCombinedDataWith = useCallback(
    //(updatedItem: T) => {
    (updatedItem) => {
        if (!updatedItemId.current)
            return;
        const { itemIdName, itemIdValue } = updatedItemId.current;
        // @ts-ignore: Unreachable code error
        const index = combinedData.findIndex((el) => el[itemIdName] === itemIdValue);
        if (index === -1) {
            // New item was created: add new one in the beginning and remove old one to the end to keep existed page size the same.
            setCombinedData((items) => [structuredClone(updatedItem), ...items.slice(0, -1)]);
            ////combinedData.current = [updatedItem, ...combinedData.current.slice(0, -1)];
        }
        else {
            //console.log('updatedItem: ', updatedItem);
            //console.log('structuredClone(updatedItem): ', structuredClone(updatedItem));
            // Update existed one.
            setCombinedData((items) => [...items.slice(0, index), structuredClone(updatedItem), ...items.slice(index + 1)]);
        }
        // console.log('in updateCombinedDataWith, index: ', index);
        // console.log('in updateCombinedDataWith, updatedItem: ', updatedItem);
        // TODO: to return if list refetch used.
        ///updatedItemId.current = undefined;
    }, [combinedData, setCombinedData]
    ////[combinedData.current]
    );
    //console.log('args', args);
    //console.log('queryParameters', queryParameters);
    //console.log('currentPage', currentPage);
    // const {
    // 	items: fetchData = [],
    // 	page: remotePage = 1,
    // 	total: remoteTotal = 0,
    // 	size: remoteSize = 10,
    //} = (queryResponse?.data as IListQueryResponse) || {};
    // TODO: actually not used now it's just not to break following code.
    //const remotePage = currentPage;
    useEffect(() => {
        setCombinedData([]);
        setCurrentPage(1);
    }, [size, search]);
    useEffect(() => {
        //console.log('fetchData: ', fetchData);
        // // First working variant.
        // if (isValidNotEmptyArray(fetchData)) {
        // 	if (currentPage === 1) setCombinedData(fetchData);
        // 	//else if (currentPage === remotePage) {
        // 	else {
        // 		setCombinedData((previousData) => [...previousData, ...fetchData]);
        // 	}
        // }
        //refetchNeeded.current = false;
        isLast.current = fetchData.length < size;
        // console.log('isLast.current: ', isLast.current);
        // console.log('fetchData.length: ', fetchData.length);
        // console.log('size: ', size);
        if (currentPage === 1) {
            setCombinedData(fetchData);
            return;
        }
        if (isValidNotEmptyArray(fetchData)) {
            //else if (currentPage === remotePage) {
            setCombinedData((previousData) => [...previousData, ...fetchData]);
        }
        // else {
        // 	isLast.current = true;
        // }
    }, [fetchData]);
    // TODO: uncomment and use when we'll have 'page' 'total' and 'size' params in IListQueryResponse.
    // const maxPages = useMemo<number>(() => {
    // 	return calculateMaxPages(remoteTotal, remoteSize);
    // }, [remoteTotal, remoteSize]);
    const refresh = useCallback((item) => {
        // If no parameters passed Just refresh and set first page.
        if (!item) {
            refetchFirstPage();
            return;
        }
        // This 'if' case is necessary only for set customer in isActive state when updatedItemId.current is undefined.
        // useGetItemQuery just doesn't work without a reason. It works on updateCustomer, but doesn't work on update iActive state.
        if (updatedItemId.current === undefined) {
            updatedItemId.current = item;
            // Just force rerender with setState.
            setCurrentPage(currentPage);
        }
        updatedItemId.current = item;
    }, [combinedData]);
    const refetchFirstPage = useCallback(() => {
        setCombinedData([]);
        setCurrentPage(1);
        query.refetch();
    }, []);
    const readMore = () => {
        setCurrentPage((page) => page + 1);
    };
    // const data2 = dataListProp ? ({ ...fullData, [dataListProp]: structuredClone(combinedData) } as T) : (fullData as T);
    // console.log('data2: ', data2);
    return {
        //data: combinedData,
        //data: { ...fullData, [dataListProp]: combinedData } as T,
        // TODO: check this! working solution on the line above.
        data: dataListProp ? { ...fullData, [dataListProp]: structuredClone(combinedData) } : fullData,
        localPage: currentPage,
        readMore,
        refresh,
        //isLast: isLast.current, // TODO: to return this back when backend bug with pageSize -1 is fixed.
        isLast: !query?.data?.isNext,
        isLoading: query?.isLoading,
        isFetching: query?.isFetching,
        isError: query?.isError,
        isSuccess: query?.isSuccess,
        error: query?.error,
    };
};
export { useKetexInfiniteScroll };
