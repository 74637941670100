import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    sidebarCollapsed: false,
};
const slice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setCollapsed: (state, action) => {
            state.sidebarCollapsed = action.payload;
        },
    },
});
export const { actions: layoutActions, reducer: layoutReducer } = slice;
