import { styled } from '@mui/material';
const Row = styled('div', {
    shouldForwardProp: (prop) => prop !== 'gap' && prop !== 'alignItems' && prop !== 'justifyContent' && prop !== 'sx',
})(({ theme, gap, alignItems, justifyContent }) => ({
    display: 'flex',
    flexDirection: 'row',
    // eslint-disable-next-line unicorn/no-nested-ternary
    gap: gap === 0 ? undefined : gap ? (typeof gap === 'number' ? `${gap / 2}rem` : gap) : '1.5rem',
    alignItems,
    justifyContent,
}));
export { Row };
