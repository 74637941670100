import { endOfDay, startOfDay, subDays, subMonths, subYears } from 'date-fns';
export const getPeriodDateRangeValues = (period) => {
    const now = new Date();
    const dayFrom = period === 'day'
        ? now
        : period === 'week'
            ? // ? subWeeks(now, 1) // 8 days actually
                subDays(now, 6) // last 7 days
            : period === 'month'
                ? // ? subMonths(now, 1) // same day (from 3rd to 3rd)
                    subDays(now, 29) // last 30 days
                : period === 'year'
                    ? subYears(now, 1)
                    : subMonths(now, 2);
    return {
        periodDateFrom: startOfDay(dayFrom),
        periodDateTo: endOfDay(now),
    };
};
