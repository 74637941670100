import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { clearIconStyles, sxClearIconButton, sxInputProps, sxRoot } from './CheckboxSelectRootButton.styled';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { TextField } from 'core/ui';
import { t } from 'shared/translations';
const CheckboxSelectRootButton = ({ selectedItemsCount, onClick, onClearButtonClick }) => {
    return (_jsx(TextField, { onClick: onClick, value: selectedItemsCount ? `${t('selected.label')}: ${selectedItemsCount}` : '', inputProps: {
            type: 'button',
            readOnly: true,
            sx: sxInputProps,
        }, endAdornment: _jsxs(_Fragment, { children: [Boolean(selectedItemsCount) && (_jsx(IconButton, { disableRipple: true, color: 'inherit', sx: sxClearIconButton, onClick: (event) => {
                        event.stopPropagation();
                        onClearButtonClick();
                    }, children: _jsx(FontAwesomeIcon, { icon: faXmark, fixedWidth: true, style: clearIconStyles }) })), _jsx(IconButton, { disableRipple: true, children: _jsx(FontAwesomeIcon, { icon: faChevronDown, fontSize: ".75rem", fixedWidth: true }) })] }), sx: sxRoot }));
};
export { CheckboxSelectRootButton };
