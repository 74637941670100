import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
export const customerApi = createApi({
    reducerPath: 'api/customers',
    tagTypes: ['Customers', 'DeliveryAddresses', 'Legals', 'Emails', 'ShippingSettings', 'PriceListMarkup', 'OrderImportRules'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getCustomers: builder.query({
            query: ({ limit = 100, offset, search }) => ({
                url: 'Customer',
                params: { limit, offset, search },
                method: 'GET',
            }),
            // 		export const mapUsers = (users: UserListItem[]) =>
            // users.map((u) => ({
            // 	...u,
            // 	modifiedLocalTime: parseDateTime(u.modifiedLocalTime)
            // }));
            transformResponse: (response) => response.map((customer) => ({ ...customer, createDate: new Date(customer.createDate) })),
            //providesTags: (result) => ['Customers'],
            //providesTags: ['Customers'],
            // providesTags: (result) =>
            // 	// is result available?
            // 	result
            // 		? // successful query
            // 		  [...result.map(({ customerId }) => ({ type: 'Customers', customerId } as const))]
            // 		: // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            // 		  ['Customers'],
        }),
        getCustomer: builder.query({
            query: (id) => ({
                method: 'GET',
                url: `Customer/${id}`,
            }),
            transformResponse: (response) => ({
                ...response,
                createDate: new Date(response.createDate),
                changeDate: new Date(response.changeDate),
                birthday: response.birthday ? new Date(response.birthday) : null,
                contractDate: new Date(response.contractDate),
            }),
            providesTags: (result, error, id) => [{ type: 'Customers', id }],
        }),
        addCustomer: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `Customer`,
                body,
            }),
        }),
        updateCustomer: builder.mutation({
            query: ({ customerId, ...body }) => ({
                method: 'PUT',
                url: `Customer/${customerId}`,
                body,
            }),
            invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', customerId }],
        }),
        getTransactions: builder.query({
            query: ({ customerId, limit = 100, offset }) => ({
                method: 'GET',
                // userfullinfo/transactions?userId=472&page=0&rowscount=50
                url: 'userfullinfo/transactions',
                params: { userid: customerId, page: offset / limit, rowscount: limit },
            }),
            transformResponse: (response) => ({
                ...response,
                payments: response.payments.map((tran) => ({ ...tran, date: new Date(tran.date) })),
            }),
        }),
        rechargeBalance: builder.mutation({
            query: ({ customerId, ...body }) => ({
                method: 'POST',
                url: `userfullinfo/addpayment`,
                params: { userid: customerId },
                body: body,
            }),
            //invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', customerId }],
        }),
        // setCustomerActive: builder.mutation<void, { customerId: number; statusId: number }>({
        setCustomerActive: builder.mutation({
            //query: ({ customerId, ...body }) => ({
            //query: (body) => ({
            query: ({ customerId, isActive }) => ({
                method: 'PUT',
                url: `Customer/${customerId}/active/${isActive}`,
                //body,
            }),
            invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', customerId }],
        }),
        getDeliveryAddresses: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/delivery-addresses`,
            }),
            providesTags: ['DeliveryAddresses'],
            // TODO: to delete this transformResponse when address is ok.
            transformResponse: (response) => {
                return response.map((r) => {
                    return { ...r, deliveryAddress: r.city };
                });
            },
        }),
        editDeliveryAddress: builder.mutation({
            //query: (body) => ({
            query: ({ deliveryAddress, customerId }) => ({
                //url: `Customer/${body.customerId}/delivery-addresses${body.deliveryAddressId ? '/' + body.deliveryAddressId : ''}`,
                method: deliveryAddress.deliveryAddressId ? 'PUT' : 'POST',
                url: `Customer/${customerId}/delivery-addresses${deliveryAddress.deliveryAddressId ? '/' + deliveryAddress.deliveryAddressId : ''}`,
                // TODO: to return this after address becomes one-line.
                //body,
                // JUST A MOCK to be deleted
                //body: { ...body, city: body.deliveryAddress, street: 'STREET', house: 'HOUSE', office: 'OFFICE', structure: 'STRUCTURE', building: 'BUILDING' },
                body: {
                    ...deliveryAddress,
                    city: deliveryAddress.deliveryAddress,
                    street: 'STREET',
                    house: 'HOUSE',
                    office: 'OFFICE',
                    structure: 'STRUCTURE',
                    building: 'BUILDING',
                },
            }),
            invalidatesTags: ['DeliveryAddresses'],
        }),
        getLegals: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/legals`,
            }),
            providesTags: ['Legals'],
        }),
        // TODO: The only one 'Legal/' endpoint. If other is found then move to separate api.
        getOwnLegalLookup: builder.query({
            query: () => ({
                method: 'GET',
                url: `Legal/OwnLegalDictionary`,
            }),
            transformResponse: (value) => {
                const arr = [];
                for (const key in value) {
                    arr.push({ ownLegalId: +key, name: value[key] });
                }
                return arr;
            },
            //providesTags: ['Legals'],
        }),
        editLegal: builder.mutation({
            query: ({ legal, customerId }) => ({
                method: legal.legalId ? 'PUT' : 'POST',
                url: `Customer/${customerId}/legals${legal.legalId ? '/' + legal.legalId : ''}`,
                body: legal,
            }),
            invalidatesTags: ['Legals'],
        }),
        getShippingSettings: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/pickingSettings`,
            }),
            providesTags: ['ShippingSettings'],
        }),
        updateShippingSettings: builder.mutation({
            query: ({ shippingSettings, customerId }) => ({
                method: 'PUT',
                url: `Customer/${customerId}/pickingSettings`,
                body: shippingSettings,
            }),
            invalidatesTags: ['ShippingSettings'],
        }),
        getEmails: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/emails`,
            }),
            providesTags: ['Emails'],
        }),
        editEmail: builder.mutation({
            query: ({ email, customerId }) => ({
                method: email.emailId ? 'PUT' : 'POST',
                url: `Customer/${customerId}/emails${email.emailId ? '/' + email.emailId : ''}`,
                body: email,
            }),
            invalidatesTags: ['Emails'],
        }),
        // getPriceListMarkup: builder.query<IPriceListMarkup[], { customerId: number | string; search: string }>({
        getPriceListMarkup: builder.query({
            query: ({ customerId, search }) => ({
                method: 'GET',
                //url: `Customer/${customerId}/priceListMarkup`,
                url: `markup/pricelist`,
                params: { userid: customerId },
            }),
            providesTags: ['PriceListMarkup'],
        }),
        setPriceListMarkup: builder.mutation({
            query: ({ customerId, priceListId, markup }) => ({
                method: 'POST',
                url: `markup/pricelist`,
                params: { userid: customerId, pricelistid: priceListId },
                body: markup || {},
            }),
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceListMarkup', priceListId }],
        }),
        // TODO: probably needed only for customers page. Btw is it still used anywhere?
        setConnectedToWebApp: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `Customer/ConnectedToWebAppState/${body.priceListId}`,
                body,
            }),
            // TODO: uncomment and check if it is working!
            //invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceLists', priceListId }],
        }),
        setConnectedToCustomer: builder.mutation({
            query: ({ customerId, priceListId, active }) => ({
                method: 'POST',
                //url: `Customer/ConnectedToCustomerState/${body.priceListId}`,
                url: `markup/pricelistavailability`,
                params: { userId: customerId, pricelistId: priceListId },
                body: active,
            }),
            // TODO: uncomment and check if it is working!
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceListMarkup', priceListId }],
        }),
        getOrderImportRules: builder.query({
            query: ({ customerId }) => ({
                method: 'GET',
                //url: `Customer/${customerId}/getOrderImportRules`,
                url: `externalOrder/list`,
                params: { userId: customerId },
            }),
            transformResponse: (response) => response.map((item) => ({ ...item, createDate: new Date(item.createDate) })).sort((a, b) => b.createDate.getTime() - a.createDate.getTime()),
            // TODO: uncomment and check if it is working!
            providesTags: ['OrderImportRules'],
            //providesTags: (result, error, id) => [{ type: 'OrderImportRules', id }],
            // providesTags: (result) =>
            // 	result
            // 		? [
            // 				...result.map(({ id }) => ({ type: 'Posts' as const, id })),
            // 				{ type: 'Posts', id: 'LIST' },
            // 			]
            // 		: [{ type: 'Posts', id: 'LIST' }],
        }),
        getOrderImportRule: builder.query({
            query: ({ ruleId }) => ({
                method: 'GET',
                //url: `Customer/${customerId}/getOrderImportRule/${ruleId}`,
                url: `externalOrder`,
                params: { settingId: ruleId },
            }),
            // forceRefetch: (params) => {
            // 	console.log('params.currentArg: ', params.currentArg);
            // 	console.log('params.previousArg: ', params.previousArg);
            // 	if (params.currentArg !== params.previousArg) return true;
            // 	return false;
            // },
            // TODO: uncomment and check if it is working!
            providesTags: ['OrderImportRules'],
        }),
        updateOrderImportRule: builder.mutation({
            query: ({ rule, customerId }) => ({
                method: 'POST',
                //url: `Customer/${customerId}/editOrdersFromFileRule${rule.externalOrderSettingsId ? '/' + rule.externalOrderSettingsId : ''}`,
                url: `externalorder`,
                //method: rule.externalOrderSettingsId ? 'PUT' : 'POST',
                body: { ...rule, userId: customerId },
            }),
            // TODO: uncomment and check if it is working!
            invalidatesTags: ['OrderImportRules'],
            //invalidatesTags: (result, error, { rule }) => [{ type: 'OrderImportRules', rule.externalOrderSettingsId }],
        }),
        deleteOrderImportRule: builder.mutation({
            query: ({ ruleId }) => ({
                method: 'DELETE',
                url: `externalOrder`,
                params: { settingId: ruleId },
            }),
            // TODO: uncomment and check if it is working!
            //invalidatesTags: ['OrderImportRules'],
            invalidatesTags: (result, error, { ruleId }) => [{ type: 'OrderImportRules', ruleId }],
        }),
        // deleteOrderImportRule: builder.query<void, {  ruleId: string }>({
        // 	query: ({ customerId, ruleId }) => ({
        // 		//url: `Customer/${customerId}/getOrderImportRule/${ruleId}`,
        // 		url: `externalOrder?settingId=115`,
        // 		param: { settingId: ruleId },
        // 		method: 'GET',
        // 	}),
        // 	// TODO: uncomment and check if it is working!
        // 	//providesTags: ['OrderImportRules']
        // }),
        // deleteOrderImportRule: builder.mutation<void, { rule: Partial<IExternalOrder>; customerId: string }>({
        // 	query: ({ rule, customerId }) => ({
        // 		url: `Customer/${customerId}/editOrdersFromFileRule${rule.externalOrderSettingsId ? '/' + rule.externalOrderSettingsId : ''}`,
        // 		method: rule.externalOrderSettingsId ? 'PUT' : 'POST',
        // 		body: rule,
        // 	}),
        // 	// TODO: uncomment and check if it is working!
        // 	//invalidatesTags: ['OrderImportRules'],
        // }),
        setOrderImportRuleActive: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `externalOrder/availability`,
                body,
            }),
            invalidatesTags: (result, error, { settingsId }) => [{ type: 'OrderImportRules', settingsId }],
        }),
        // TODO: use proper method after it is created on backend.
        getExportPriceLists: builder.query({
            query: () => ({
                method: 'GET',
                //url: `Customer/exportpricelist`,
                url: 'exportpricelist',
            }),
        }),
    }),
});
export const { useGetCustomersQuery, useGetCustomerQuery, useUpdateCustomerMutation, useGetTransactionsQuery, useRechargeBalanceMutation, useSetCustomerActiveMutation, useAddCustomerMutation, useGetDeliveryAddressesQuery, useEditDeliveryAddressMutation, useGetLegalsQuery, useEditLegalMutation, useGetOwnLegalLookupQuery, useGetShippingSettingsQuery, useUpdateShippingSettingsMutation, useGetEmailsQuery, useEditEmailMutation, useGetPriceListMarkupQuery, useSetPriceListMarkupMutation, useSetConnectedToWebAppMutation, useSetConnectedToCustomerMutation, useGetOrderImportRulesQuery, useGetOrderImportRuleQuery, useUpdateOrderImportRuleMutation, useDeleteOrderImportRuleMutation, useSetOrderImportRuleActiveMutation, useGetExportPriceListsQuery, } = customerApi;
