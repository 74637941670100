import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// TODO: move to another folder?
// const Form = styled('form', { shouldForwardProp: (prop) => prop !== 'divider' && prop !== 'noPaddingTop' })<{
// const Form = styled('form')<{
const FormBox = ({ children, ...props }) => {
    return (_jsx(BoxFlex1, { component: "form", ...props, children: children }));
    // return <Box component="form">{children}</Box>;
    // <BoxFlex1
};
const BoxFlex1 = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
}));
export { FormBox };
