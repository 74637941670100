import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseButtonContainer, IconContainer, RootContainer, SnackbarContent, SubmissionSnackTypography, TextContentContainer, } from './ReceiptSubmissionSnackbar.styled';
import { SnackbarCloseButton } from './SnackbarCloseButton';
import { SnackbarIcon } from './SnackbarIcon';
import { forwardRef } from 'react';
import { t } from 'shared/translations';
const articleSearchTermMaxLength = 23;
const ReceiptSubmissionSnackbar = forwardRef(({ brand, article, id, variant, ...props }, ref) => {
    return (_jsx(SnackbarContent, { ref: ref, children: _jsxs(RootContainer, { alignCenter: true, variant: variant, children: [_jsx(IconContainer, { children: _jsx(SnackbarIcon, { variant: variant }) }), _jsxs(TextContentContainer, { children: [variant === 'receiptSubmissionSuccess' && (_jsxs(_Fragment, { children: [_jsx(SubmissionSnackTypography, { fontWeight: "medium", children: brand }), _jsx(SubmissionSnackTypography, { fontWeight: "regular", children: article })] })), variant === 'receiptSubmissionError' && (_jsxs(_Fragment, { children: [_jsx(SubmissionSnackTypography, { fontWeight: "light", children: t('item.label') }), _jsx(SubmissionSnackTypography, { fontWeight: "regular", children: article.substring(0, articleSearchTermMaxLength) }), _jsx(SubmissionSnackTypography, { fontWeight: "light", children: t('item.not.found') })] }))] }), _jsx(CloseButtonContainer, { variant: variant, children: _jsx(SnackbarCloseButton, { snackbarKey: id }) })] }) }));
});
export { ReceiptSubmissionSnackbar };
