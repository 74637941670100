import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Chip, TextField as MuiTextField } from '@mui/material';
import { t } from 'shared/translations';
import { useState } from 'react';
//const ServerSearchAutocomplete = React.forwardRef(<T, >({ getQuery, onChange, value, labelProperty, isError: isErrorState, ...props }: IProps<T>, ref: React.Ref<T>) => {
/**
 * Sends remote request with search string and fills options with request response.
 * Should be used when just an array of string is returned by *getQuery*.
 * @param multiple forces component to have Chips, otherwise text is used.
 * @param getQuery rtk useQuery that makes remote search and returns an array of string.
 * @returns an array with string values
 */
const ServerSearchAutocompleteSimple = ({ multiple, isError: isErrorState, getQuery, onChange, ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');
    // TODO: use isQueryError
    const { data, isError: isQueryError } = getQuery({ search: searchTerm }, { skip: searchTerm.length < 2 });
    // TODO: still not checked in multiple=false mode
    return (_jsx(Autocomplete
    // @ts-ignore
    , { 
        // @ts-ignore
        multiple: multiple, 
        //freeSolo // Not to show empty "No options"?
        freeSolo // Returned to fix Mui console warning about item is not in list (None of the options match with `"LYKMC"`).
        : true, filterSelectedOptions: true, ...props, value: multiple ? props.value || undefined : props.value || null, clearText: t('delete.all'), options: data || [], 
        // Fix console error "... `getOptionLabel` method of Autocomplete returned object (BMW) instead of a string ["BMW"] ..."
        // Only reproduced when multiple={false}
        getOptionLabel: (option) => option || '', filterOptions: (x) => x, 
        // Multiple forces component to have Chips, otherwise text is used.
        renderTags: multiple
            ? (value, getTagProps, ownerState) => {
                return value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                _jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: option, ...getTagProps({ index }) })));
            }
            : undefined, renderInput: (params) => (
        // Only Mui TextFiled works inside Autocomplete.
        _jsx(MuiTextField, { ...params, size: "small", placeholder: props.placeholder ?? t('search.label'), error: isErrorState, sx: multiple ? undefined : { '.MuiOutlinedInput-input': { height: '19px' } } })), onChange: (event, value) => {
            onChange(value);
        }, onInputChange: (event, value, reason) => {
            setSearchTerm(value.trim());
        }, noOptionsText: t('please.enter.the.first.two.letters.to.search') }));
};
export { ServerSearchAutocompleteSimple };
