import { jsx as _jsx } from "react/jsx-runtime";
import { PeriodDateRange, periodDateRangeDeserializer } from 'core/ui';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCoreSessionStorage, useDebounce } from 'core/hooks';
import { useEffect, useMemo } from 'react';
const debouncedDateRangeValueDelay = 900;
/**
 * Enhances {@link PeriodDateRange} component.
 *
 * Addons: stores value in *sessionStorage*, adds debounce on value change.
 *
 * **Important**: should be used with explicit generic type.
 *
 * @param {string} sessionStorageKey - key under which the value will be stored.
 * @param {?boolean} disabled - optional
 * @param {(value: DateRangeFromTo) => void} onChange
 * @returns
 */
const StoredPeriodDateRange = ({ sessionStorageKey, disabled, onChange }) => {
    const initialValue = useMemo(() => ({
        period: 'month',
        periodDateFrom: startOfDay(subDays(new Date(), 29)),
        periodDateTo: endOfDay(new Date()),
    }), []);
    const [value, setValue] = useCoreSessionStorage(sessionStorageKey, initialValue, {
        deserializer: periodDateRangeDeserializer(initialValue),
    });
    const debouncedValue = useDebounce(value, debouncedDateRangeValueDelay);
    useEffect(() => {
        onChange({ dateFrom: debouncedValue.periodDateFrom, dateTo: debouncedValue.periodDateTo });
    }, [debouncedValue, onChange]);
    return _jsx(PeriodDateRange, { value: value, disabled: disabled, invalid: false, onChange: setValue });
};
export { StoredPeriodDateRange };
