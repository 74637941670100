/* eslint-disable unicorn/better-regex */
/* eslint-disable no-useless-escape */
// yup's email() is not strong. Allows 'email@email'.
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Same as above but allows russian letters in domain.
export const russianDomainEmailRegex = /^[A-Za-z0-9а-яА-Я\._-]+@([A-Za-z0-9а-яА-Я]{1,2}|[A-Za-z0-9а-яА-Я]((?!(\.\.))[A-Za-z0-9а-яА-Я.-])+[A-Za-z0-9а-яА-Я])\.[A-Za-zа-яА-Я]{2,}$/;
export const engLettersOnlyRegex = /^[A-Za-z]+$/; // /^\p{Letter}+/gu
export const engLettersAndNumbersOnlyRegex = /^[A-Za-z0-9]*$/;
export const engLetterOrNumberOnlyRegex = /^[A-Za-z0-9]$/;
export const engRusLetterOrNumberOnlyRegex = /^[A-Za-zа-яА-Я0-9]$/;
export const rusLetterOnlyRegex = /^[а-яА-Я]$/;
export const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const timeRegexDigitsOnly = /^([0-1]?[0-9]|2[0-3])[0-5][0-9]$/;
export const hoursRegex = /^([01]?[0-9]|2[0-4])$/;
export const getFileExtensionPattern = /(?:\.([^.]+))?$/; // use with pattern.exec(someVar)
