import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { startOfDay, subMonths } from 'date-fns';
import { DatePicker } from 'core/ui';
import { PeriodDateRangeInnerContainer } from './PeriodDateRange.styled';
import { getPeriodDateRangeValues } from './helpers/getPeriodDateRangeValues';
import { t } from 'shared/translations';
/**
 * Sets date range and triggers {@link onChange} callback.
 * @param {PeriodDateRangeValue} value - Value
 * @param {(value: PeriodDateRangeValue) => void} onChange - callback that is called on value change.
 * @param {boolean | undefined} disabled - Switch the component to disabled state.
 * @param {boolean} invalid - Shows that value entered is invalid.
 */
const PeriodDateRange = ({ value, disabled, invalid, onChange }) => {
    const { period, periodDateFrom, periodDateTo } = value;
    const setCustomPeriodRange = () => {
        const now = new Date();
        onChange({ period: 'custom', periodDateFrom: startOfDay(subMonths(now, 6)), periodDateTo });
    };
    const handleDateFromChange = (value) => {
        onChange({ period: 'custom', periodDateFrom: value, periodDateTo });
    };
    const handleDateToChange = (value) => {
        onChange({ period: 'custom', periodDateFrom, periodDateTo: value });
    };
    return (_jsxs(PeriodDateRangeInnerContainer, { children: [_jsxs(ToggleButtonGroup, { exclusive: true, color: "primary", size: "medium", value: period, disabled: disabled, onChange: (_, newPeriod) => {
                    if (newPeriod === null || newPeriod === period)
                        return;
                    if (newPeriod === 'custom') {
                        setCustomPeriodRange();
                        return;
                    }
                    onChange({ period: newPeriod, ...getPeriodDateRangeValues(newPeriod) });
                }, children: [_jsx(ToggleButton, { value: "day", children: t('day.label') }), _jsx(ToggleButton, { value: "week", children: t('week.label') }), _jsx(ToggleButton, { value: "month", children: t('month.label') }), _jsx(ToggleButton, { value: "year", children: t('year.label') }), _jsx(ToggleButton, { value: "custom", children: t('period.label').toLocaleLowerCase() })] }), _jsx(DatePicker, { width: "dense", defaultValue: periodDateFrom, value: periodDateFrom, onChange: handleDateFromChange, disabled: period !== 'custom' || disabled, invalid: invalid }), _jsx(Typography, { children: `-` }), _jsx(DatePicker, { width: "dense", defaultValue: periodDateTo, value: periodDateTo, onChange: handleDateToChange, disabled: period !== 'custom' || disabled, invalid: invalid })] }));
};
export { PeriodDateRange };
