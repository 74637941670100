import { faCircle, faDollarSign, faEuroSign, faRubleSign, faYenSign } from '@fortawesome/free-solid-svg-icons';
const getCurrencyIconByCode = (code) => {
    switch (code) {
        case 1: {
            return faRubleSign;
        }
        case 840: {
            return faDollarSign;
        }
        case 978: {
            return faEuroSign;
        }
        case 156:
        case 392: {
            return faYenSign;
        }
        default: {
            return faCircle;
        }
    }
};
export { getCurrencyIconByCode };
