import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiQuery, blobResponseHandler } from 'shared/helpers/api';
import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { defaultValues as priceListDefaultValues } from '../components/PriceListEditDialog/PriceListEditDialog.yup';
import { t } from 'shared/translations';
export const priceListApi = createApi({
    reducerPath: 'api/priceLists',
    tagTypes: ['PriceList'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getPriceLists: builder.query({
            query: () => ({
                method: 'GET',
                url: 'exportpricelist',
            }),
            transformResponse: (response) => response.map((priceList) => ({ ...priceList, lastUpdate: new Date(priceList.lastUpdate) })),
            providesTags: (result, error, arg) => result ? [...result.map(({ exportPriceListId }) => ({ type: 'PriceList', id: exportPriceListId })), 'PriceList'] : ['PriceList'],
        }),
        setPriceListActive: builder.mutation({
            // query: (body) => ({
            query: ({ priceListId, isActive }) => ({
                method: 'POST',
                url: 'exportpricelist/activity',
                //body,
                body: {
                    exportPriceListId: priceListId,
                    isActive,
                },
            }),
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceList', priceListId }],
        }),
        // downloadPriceList: builder.query<void, { priceListId: number }>({
        // 	query: ({ priceListId }) => ({
        // 		method: 'GET',
        // 		// url: 'exportpricelist/file?id=110',
        // 		url: 'exportpricelist/file',
        // 		params: { id: priceListId },
        // 		body: { id: priceListId },
        // 	}),
        // }),
        downloadPriceList: builder.mutation({
            queryFn: async ({ priceListId }, api, extraOptions, baseQuery) => {
                const result = (await baseQuery({
                    url: 'exportpricelist/file',
                    params: { id: priceListId },
                    cache: 'no-cache',
                    responseHandler: blobResponseHandler,
                }));
                // TODO: use downloadBlob here
                if (result.error) {
                    enqueueSnackbar({
                        variant: 'error',
                        header: t('file.download.failed'),
                        body: getErrorFromException(result.error),
                        persist: true,
                    });
                    return { data: null };
                }
                const hiddenElement = document.createElement('a');
                const url = window.URL || window.webkitURL;
                const priceListBlob = url.createObjectURL(result.data);
                hiddenElement.href = priceListBlob;
                hiddenElement.target = '_blank';
                hiddenElement.download = `Прайс_${priceListId}.xlsx`;
                hiddenElement.click();
                URL.revokeObjectURL(priceListBlob);
                return { data: null };
            },
        }),
        getPriceList: builder.query({
            // query: (priceListId) => ({
            // 	method: 'GET',
            // 	url: 'exportpricelist',
            // 	params: { id: priceListId },
            // }),
            // providesTags: ['PriceList'],
            // transformResponse: (response: Omit<IPriceListUpdate, 'lastUpdate'> & { lastUpdate: string }) => ({
            // 	...response,
            // 	//lastUpdate: new Date(response.lastUpdate),
            // 	includedPricelists: response.includedPricelists.map((includedPriceList) => ({
            // 		//...includedPriceList,
            // 		//updated: new Date(includedPriceList.updated),
            // 		pricelistId: includedPriceList!.pricelistId,
            // 		logo: includedPriceList!.logo,
            // 		markup: includedPriceList!.markup,
            // 		checked: true,
            // 	})),
            // 	// Times coming as 10:30:00 instead of 10:30. Convert.
            // 	scheduleTime: response.scheduleTime.find((el) => el.length > 5) ? response.scheduleTime.map((el) => el.slice(0, 5)) : response.scheduleTime,
            // }),
            queryFn: async (priceListId, api, extraOptions, baseQuery) => {
                // await Promise.All([]) can be used here, but it will complicate logic.
                // New price list if no priceListId
                const priceListResult = priceListId
                    ? await baseQuery({ method: 'GET', url: 'exportpricelist', params: { id: priceListId } })
                    : { data: priceListDefaultValues };
                const priceListsForExportResult = await baseQuery({
                    method: 'GET',
                    url: 'exportpricelist/prices4export',
                });
                //if (Boolean(priceListsForExportResult.error)) return { data: null };
                const priceList = priceListResult.data;
                const priceListsForExport = priceListsForExportResult.data;
                const combined = priceListsForExport.map((el) => {
                    const included = priceList.includedPricelists.find((item) => item.pricelistId === el.pricelistId);
                    return { ...el, checked: Boolean(included), markup: included?.markup ?? null };
                });
                return {
                    data: {
                        ...priceList,
                        includedPricelists: combined.map((includedPriceList) => ({
                            pricelistId: includedPriceList.pricelistId,
                            logo: includedPriceList.logo,
                            markup: includedPriceList.markup,
                            checked: includedPriceList.checked,
                        })),
                        // Times coming as 10:30:00 instead of 10:30. Convert.
                        scheduleTime: priceList.scheduleTime.some((el) => el.length > 5)
                            ? priceList.scheduleTime.map((el) => el.slice(0, 5))
                            : priceList.scheduleTime,
                    },
                };
            },
            providesTags: (result, error, id) => [{ type: 'PriceList', id }],
        }),
        updatePriceList: builder.mutation({
            query: ({ priceList }) => ({
                method: 'POST',
                url: 'exportpricelist',
                body: priceList,
            }),
            invalidatesTags: (result, error, { priceList }) => priceList.exportPriceListId
                ? [{ type: 'PriceList', id: priceList.exportPriceListId }]
                : [{ type: 'PriceList' }],
        }),
        deletePriceList: builder.mutation({
            query: ({ priceListId }) => ({
                method: 'DELETE',
                url: 'exportpricelist',
                params: { id: priceListId },
            }),
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceList', id: priceListId }],
        }),
        getPriceListsForExport: builder.query({
            query: () => ({
                method: 'GET',
                url: 'exportpricelist/prices4export',
            }),
            transformResponse: (response) => response.map((priceList) => ({ ...priceList, updated: new Date(priceList.updated) })),
        }),
        checkFtpConnection: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'ExportPricelist/CheckFtpConnection',
                body,
            }),
        }),
        // TODO: extract this method to new unique articles API, since it used not only in PriceLists but also in Stock, etc.
        getArticlesFiltered: builder.query({
            query: ({ search }) => ({
                method: 'GET',
                url: 'ExportPricelist/articles',
                params: { filter: search },
                //body,
            }),
        }),
        // TODO: Refactor later, when new api is done.
        // This method from old Backend is already existed in brands api and returns array of objects.
        // This version returns array of string.
        getBrandsAsStringArrayFiltered: builder.query({
            query: ({ search }) => ({
                method: 'GET',
                url: 'ExportPricelist/brands',
                params: { filter: search },
                //body,
            }),
        }),
    }),
});
export const { useGetPriceListsQuery, useSetPriceListActiveMutation, useDownloadPriceListMutation, useGetPriceListQuery, useUpdatePriceListMutation, useDeletePriceListMutation, useGetPriceListsForExportQuery, useLazyCheckFtpConnectionQuery, useGetArticlesFilteredQuery, useGetBrandsAsStringArrayFilteredQuery, } = priceListApi;
