import { useEffect, useState } from 'react';
/**
 * Returns debounced value on *value* change with debounce *delay*.
 *
 * @param {T} value - Value to be debounced
 * @param {number} delay - Delay
 */
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
};
export { useDebounce };
