import { jsx as _jsx } from "react/jsx-runtime";
import { OutlinedInput } from '@mui/material';
import React from 'react';
/**
 * **Global Parts** TextFiled.
 *
 * Uses MuiOutlinedInput under the hood and handles console error when 'undefined' is passed as value.
 */
const TextField = React.forwardRef(({ value, ...props }, ref) => {
    //const theme = useTheme();
    //const classes = styles(theme);
    // let inputProps: InputBaseComponentProps = {};
    // inputProps.style = { padding: '5px !important' };
    //return <OutlinedInput label="" {...props} inputProps={inputProps} />;
    //return <OutlinedInput label="" {...props} inputProps={{ style: { padding: '20px' } }} />;
    //return <OutlinedInput label="" {...props} sx={{ height: '100px' }} />;
    //return <OutlinedInput label="" {...props} sx={sx} />;
    //return <OutlinedInput ref={ref} label="" value={value === null ? undefined : value} {...props} />;
    //return <OutlinedInput label="" {...props} classes={{ root: classes.root}} />;
    // '': https://react.dev/reference/react-dom/components/input#controlling-an-input-with-a-state-variable
    return _jsx(OutlinedInput, { ref: ref, label: "", value: value === null || value === undefined ? '' : value, ...props });
    //return <OutlinedInput ref={ref} label="" value={value === null ? '' : value} {...props} />;
    // return <InputBase ref={ref} value={value === null || value === undefined ? '' : value} {...props} />;
});
const TextFieldUncontrolled = React.forwardRef(({ value, ...props }, ref) => {
    return _jsx(OutlinedInput, { ref: ref, label: "", value: value === null ? '' : value, ...props });
});
export { TextFieldUncontrolled, TextField };
