import { faBoxesStacked, faChartSimple, faDolly, faFileExcel, faGear, faHandHoldingDollar, faListUl, faShippingFast, faUsers, } from '@fortawesome/free-solid-svg-icons';
import { t } from 'shared/translations';
export const sidebarNavigationItems = [
    {
        sectionName: t('general.sidebar.section'),
        menuItems: [
            {
                linkTo: '/orders',
                title: t('orders.section'),
                icon: faListUl,
                allow: ['CustomerHeadManager', 'CustomerManager', 'SupplierHeadManager', 'SupplierManager'],
            },
            {
                linkTo: '/customers',
                title: t('clients.section'),
                icon: faUsers,
                allow: ['CustomerHeadManager', 'CustomerManager'],
            },
            {
                linkTo: '/suppliers',
                title: t('suppliers.section'),
                icon: faShippingFast,
                allow: ['SupplierHeadManager', 'SupplierManager'],
            },
            {
                linkTo: '/priceLists',
                title: t('pricelists.section'),
                icon: faFileExcel,
            },
            {
                linkTo: '/reclamations',
                title: t('reclamations.section'),
                icon: faHandHoldingDollar,
            },
            {
                linkTo: '/stock',
                title: t('warehouses.label'),
                icon: faBoxesStacked,
                allow: 'Warehouse',
            },
            {
                linkTo: '/shipment',
                title: t('shipment.label'),
                icon: faDolly,
                allow: ['CustomerHeadManager', 'CustomerManager'],
            },
        ],
    },
    {
        sectionName: t('reports.section'),
        menuItems: [
            {
                linkTo: '/reports/suppliers',
                title: t('display.by.suppliers'),
                icon: faChartSimple,
            },
            {
                linkTo: '/reports/customers',
                title: t('reports-clients.section'),
                icon: faChartSimple,
            },
            {
                linkTo: '/reports/payments',
                title: t('reports-payments.section'),
                icon: faChartSimple,
            },
        ],
    },
    {
        sectionName: t('settings.label'),
        menuItems: [
            {
                linkTo: '/settings',
                title: t('settings.section'),
                icon: faGear,
            },
        ],
    },
];
