import { jsx as _jsx } from "react/jsx-runtime";
import { Typography, styled } from '@mui/material';
import { useCopyToClipboard } from 'usehooks-ts';
const CopyTypography = (props) => {
    const [copiedText, copy] = useCopyToClipboard();
    const handleClick = () => {
        const { children } = props;
        if (!children || !(typeof children === 'string' || typeof children === 'number'))
            return;
        copy(`${children}`);
    };
    return _jsx(TypographyStyled, { ...props, onClick: handleClick });
};
const TypographyStyled = styled(Typography)(({ theme }) => ({
    ':hover': {
        color: theme.palette.primary.dark,
    },
}));
export { CopyTypography };
