import { UploadMethod } from 'admin/modules/suppliers/models';
import { t } from 'shared/translations';
const getUploadMethodLabelById = (uploadMethod) => {
    if (uploadMethod === UploadMethod.Manual)
        return t('manual.update.label');
    if (uploadMethod === UploadMethod.Email)
        return t('email.label');
    if (uploadMethod === UploadMethod.ExternalFile)
        return t('external.file.label');
    if (uploadMethod === UploadMethod.Stock)
        return t('stock.label');
    console.error('Unknown uploadMethod id:', uploadMethod);
    return null;
};
export { getUploadMethodLabelById };
