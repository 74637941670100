import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseButtonContainer, IconContainer, RootContainer, TextContentContainer, snackbarContentStyle } from './SimpleSnackbar.styled';
import { SnackbarContent } from 'notistack';
import { SnackbarCloseButton } from './SnackbarCloseButton';
import { SnackbarIcon } from './SnackbarIcon';
import { Typography } from '@mui/material';
import { forwardRef } from 'react';
// TODO: to delete all comments later if everything works fine (there was a problem with this).
// const SimpleSnackbar = forwardRef<HTMLDivElement, SimpleSnackbarProps>(({ id, header, body, ...props }, ref) => {
const SimpleSnackbar = forwardRef(({ header, body, id, variant, ...props }, ref) => {
    return (
    // <SnackbarContent ref={ref} {...props} style={snackbarContentStyle}> // Removed because of console errors.
    _jsx(SnackbarContent, { ref: ref, style: snackbarContentStyle, children: _jsxs(RootContainer, { alignCenter: header !== undefined && !body, children: [variant !== 'default' && (
                // {props.variant !== 'default' && (
                _jsx(IconContainer, { children: _jsx(SnackbarIcon, { variant: variant }) })), _jsxs(TextContentContainer, { children: [header && (_jsx(Typography, { variant: "body1", fontWeight: ({ typography }) => typography.fontWeightMedium, children: header })), body && (_jsx(Typography, { variant: "body1", marginTop: ".25rem", color: ({ palette }) => palette.text.muted, children: body }))] }), _jsx(CloseButtonContainer, { children: _jsx(SnackbarCloseButton, { snackbarKey: id }) })] }) }));
});
export { SimpleSnackbar };
