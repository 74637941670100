import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { CheckedIcon, Icon } from './IconContainer.styled';
import { IconContainer } from './IconContainer';
const Checkbox = React.forwardRef((props, ref) => {
    return (_jsx(MuiCheckbox, { disableRipple: true, ...props, 
        //checked={checked ?? value ?? false} // value is also used, don't restore
        inputRef: ref, icon: _jsx(IconContainer, { children: _jsx(Icon, {}) }), checkedIcon: _jsx(IconContainer, { children: _jsx(CheckedIcon, { color: props.color }) }), indeterminateIcon: _jsx(IconContainer, { children: _jsx(CheckedIcon, { indeterminate: true, color: props.color }) }) }));
});
export { Checkbox };
