const sxRoot = {
    paddingLeft: '.25rem',
    paddingRight: '.25rem',
    ':hover': { 'button:nth-of-type(1)': { display: 'inline-flex' } },
};
const sxInputProps = {
    textAlign: 'left',
    ':hover': { cursor: 'pointer' },
};
const sxClearIconButton = { display: 'none' };
const clearIconStyles = { fontSize: '.85rem', paddingTop: '1px' };
export { sxRoot, sxInputProps, sxClearIconButton, clearIconStyles };
