import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiQuery } from 'shared/helpers/api';
export const stockSettingsApi = createApi({
    reducerPath: 'api/settings/stock',
    tagTypes: ['StockList', 'Stock'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getStockSettingsList: builder.query({
            query: () => {
                return {
                    method: 'GET',
                    url: 'stocksettings/stockslist',
                };
            },
            transformResponse: (response) => ({
                stock: Object.hasOwn(response, 1) ? response[1] : [],
                crossdocking: Object.hasOwn(response, 2) ? response[2] : [],
                transfer: [],
            }),
            providesTags: ['StockList'],
        }),
        // This two queries was converted to one following below, because the results of both are interdependent.
        // getStockSettings: builder.query<IStock, { stockId: number }>({
        // 	query: ({ stockId }) => {
        // 		return {
        // 			method: 'GET',
        // 			url: `stocksettings/getStock/${stockId}`,
        // 		};
        // 	},
        // 	providesTags: (result, error, { stockId }) => [{ type: 'Stock', stockId }],
        // }),
        // getStockCellRanges: builder.query<IStockCellRange[], void>({
        // 	query: () => {
        // 		return {
        // 			method: 'GET',
        // 			url: 'stocksettings/stockCellRanges',
        // 		};
        // 	},
        // 	forceRefetch: () => true, // TODO: to delete? it is not working
        // 	//providesTags: (result, error, { stockId }) => [{ type: 'Stock', stockId }],
        // 	providesTags: ['Stock'],
        // }),
        // The results of both are interdependent: when cell range added to stockSetting cellRanges (on edit), than it will be removed
        // from unassignedRanges of following query.
        // In case of two separate query it can lead to inconsistency during ui components data refresh.
        getStockSettings: builder.query({
            queryFn: async ({ stockId }, api, extraOptions, baseQuery) => {
                const stockSettingQuery = baseQuery({ method: 'GET', url: `stocksettings/getStock/${stockId}` });
                const stockCellRangesQuery = baseQuery({ method: 'GET', url: `stocksettings/stockCellRanges` });
                const bothQueries = Promise.all([stockSettingQuery, stockCellRangesQuery]);
                try {
                    const result = await bothQueries;
                    return { data: { stockSetting: result[0].data, unassignedRanges: result[1].data } };
                }
                catch (error) {
                    return { error: error };
                }
            },
            providesTags: (result, error, { stockId }) => [{ type: 'Stock', stockId }],
        }),
        // addStockSettings: builder.mutation<void, IStockSettingsAdd>({
        // 	query: (body) => ({
        // 		method: 'POST',
        // 		url: 'stocksettings/addStock',
        // 		body,
        // 	}),
        // 	invalidatesTags: (result, error, stockItems) => [{ type: 'StockList' }],
        // }),
        // We use only one method not to have double mutations in ui component
        editStockSettings: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `stocksettings/${body.stockId ? 'update' : 'add'}Stock`,
                body,
            }),
            invalidatesTags: (result, error, stockItems) => [{ type: 'StockList' }],
        }),
        deleteStockSettings: builder.mutation({
            query: ({ stockId }) => ({
                method: 'DELETE',
                url: `stocksettings/deleteStock/${stockId}`,
            }),
            invalidatesTags: (result, error, { stockId }) => [{ type: 'StockList', stockId }],
        }),
    }),
});
export const { useGetStockSettingsListQuery, useGetStockSettingsQuery, useEditStockSettingsMutation, useDeleteStockSettingsMutation } = stockSettingsApi;
