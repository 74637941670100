import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiQuery, blobResponseHandler } from 'shared/helpers/api';
import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { getOrdersRequestPayload } from './order.helpers';
import { t } from 'shared/translations';
const ordersPageSize = 100;
export const ordersApi = createApi({
    reducerPath: 'api/orders',
    tagTypes: ['Orders', 'StatusHistory'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: ({ limit = ordersPageSize, offset, search, filter }) => ({
                method: 'POST',
                url: 'Order/getfilteredorders',
                // body: {
                // 	page: offset / limit,
                // 	rowsPerPage: limit,
                // 	search,
                // 	startDate: filter.startDate,
                // 	endDate: filter.endDate,
                // 	startPrice: filter.startPrice,
                // 	endPrice: filter.endPrice,
                // 	startQty: filter.startQty,
                // 	endQty: filter.endQty,
                // 	statuses: filter.statuses,
                // 	customers: filter.customers,
                // 	suppliers: filter.suppliers,
                // 	customerManagers: filter.customerManagers,
                // 	supplierManagers: filter.supplierManagers,
                // 	logos: filter.logos,
                // 	orders: filter.orders,
                // 	brands: filter.brands,
                // 	articles: filter.articles,
                // 	sortColumn: null,
                // 	sortDirectionAsc: false,
                // } as OrdersApiRequest,
                body: getOrdersRequestPayload({ page: offset / limit, rowsPerPage: limit, search, filter }),
            }),
            transformResponse: (response) => {
                //response = {...response, orders: }
                //return response.orders.map((order) => ({ ...order, addDate: new Date(order.addDate) }));
                return {
                    ...response,
                    //filters: { ...response.filters, startDate: new Date(response.filters.startDate), endDate: new Date(response.filters.endDate) },
                    //orders: response.orders.map((order) => ({ ...order, addDate: new Date(order.addDate), changeDate: new Date(order.changeDate) })),
                    orders: response.orders.map((orderSupplierUser) => ({
                        ...orderSupplierUser,
                        order: {
                            ...orderSupplierUser.order,
                            addDate: new Date(orderSupplierUser.order.addDate),
                            changeDate: new Date(orderSupplierUser.order.changeDate),
                            deliveryDate: new Date(orderSupplierUser.order.deliveryDate),
                            // addDate: parseISO(orderSupplierUser.order.addDate as any),
                            // changeDate: parseISO(orderSupplierUser.order.changeDate as any),
                        },
                        //supplier: orderSupplierUser.supplier,
                        //user: orderSupplierUser.user
                    })),
                };
            },
        }),
        getOrderCount: builder.query({
            query: ({ limit = 100, offset, search, filter }) => ({
                method: 'POST',
                url: `order/getfilteredcount`,
                // body: {
                // 	page: offset / limit,
                // 	rowsPerPage: limit,
                // 	search,
                // 	startDate: filter.startDate,
                // 	endDate: filter.endDate,
                // 	startPrice: filter.startPrice,
                // 	endPrice: filter.endPrice,
                // 	startQty: filter.startQty,
                // 	endQty: filter.endQty,
                // 	statuses: filter.statuses,
                // 	customers: filter.customers,
                // 	suppliers: filter.suppliers,
                // 	customerManagers: filter.customerManagers,
                // 	supplierManagers: filter.supplierManagers,
                // 	logos: filter.logos,
                // 	orders: filter.orders,
                // 	brands: filter.brands,
                // 	articles: filter.articles,
                // 	sortColumn: null,
                // 	sortDirectionAsc: false,
                // } as OrdersApiRequest,
                body: getOrdersRequestPayload({ page: offset / limit, rowsPerPage: limit, search, filter }),
            }),
        }),
        // Used for sync update order changes with list.
        getOrderInfo: builder.query({
            // query: (orderId) => ({
            // 	method: 'GET',
            // 	url: `Order/info/${orderId}`,
            // }),
            async queryFn(orderId, _queryApi, _extraOptions, fetchWithBQ) {
                const orderInfoResult = await fetchWithBQ(`/Order/info/${orderId}`);
                if (orderInfoResult.error)
                    return { error: orderInfoResult.error };
                const orderInfo = orderInfoResult.data;
                const orderCustomerResult = await fetchWithBQ(`/Customer/${orderInfo.userId}`);
                const orderUser = orderCustomerResult.data;
                const result = {
                    order: {
                        ...orderInfo,
                        // @ts-ignore we have 'portion' instead of 'portions' like in IOrder for some reason.
                        portions: orderInfo.portion.map((portion) => {
                            return {
                                ...portion,
                                addDate: new Date(portion.addDate),
                                invoiceDate: portion.invoiceDate ? new Date(portion.invoiceDate) : null,
                            };
                        }),
                        // @ts-ignore
                        comment: orderInfo.comment,
                    },
                    user: {
                        balance: orderUser.balance ?? 0,
                        userId: 1234,
                        firstName: 'notUsedName',
                    },
                    supplier: {
                        supplierId: 1234,
                        supplierLegal: {
                            legalId: 1234,
                            name: 'name',
                        },
                    },
                };
                return orderCustomerResult.data ? { data: result } : { error: orderCustomerResult.error };
            },
            providesTags: (result, error, id) => [{ type: 'Orders', id }],
        }),
        // // TODO: to delete probably.
        // getOrder: builder.query<IOrderListItem, number>({
        // 	query: (id) => ({
        // 		url: `Order/${id}`,
        // 		method: 'GET',
        // 	}),
        // 	// transformResponse: (response: IOrderListItem) => ({
        // 	// 	...response,
        // 	// 	order: {
        // 	// 		...response.order,
        // 	// 		addDate: new Date(response.order.addDate),
        // 	// 		changeDate: new Date(response.order.changeDate),
        // 	// 		deliveryDate: new Date(response.order.deliveryDate),
        // 	// 		invoiceDate: response.order.invoiceDate ? new Date(response.order.invoiceDate) : null,
        // 	// 		readyDate: new Date(response.order.readyDate),
        // 	// 	},
        // 	// }),
        // 	providesTags: (result, error, id) => [{ type: 'Orders', id }],
        // }),
        getOrderStatuses: builder.query({
            query: () => ({
                method: 'GET',
                url: `portion/portionstates`,
            }),
            // transformResponse: (value: any) => {
            transformResponse: (value) => {
                const array = [];
                for (const key in value) {
                    array.push({ id: +key, value: value[key] });
                }
                return array;
            },
        }),
        updateOrderStatus: builder.mutation({
            query: ({ portionId, statusId }) => ({
                method: 'POST',
                url: `portion`,
                body: { portionId, state: statusId },
            }),
            invalidatesTags: (result, error, { portionId }) => [
                { type: 'Orders', portionId },
                { type: 'StatusHistory', portionId },
            ],
        }),
        getOrderStatusHistory: builder.query({
            query: ({ portionId }) => ({
                method: 'GET',
                url: `portion/log`,
                params: { portionId },
            }),
            transformResponse: (response) => response.map((portion) => ({
                ...portion,
                addDate: new Date(portion.addDate),
                invoiceDate: portion.invoiceDate ? new Date(portion.invoiceDate) : null,
            })),
            providesTags: (result, error, portionId) => [{ type: 'StatusHistory', portionId }],
        }),
        // getOrderFilters: builder.query<{ suppliers: ISupplierLookupItem[]; users: ICustomerLookupItem[]  }[], void>({
        // TODO: rename according to new BL entities.
        getSuppliersCustomersLookups: builder.query({
            query: () => ({
                method: 'GET',
                url: `order/getorderfilters`,
            }),
            transformResponse: (value) => {
                return {
                    suppliers: value.suppliers.map((item) => ({ id: item.supplierId, value: item.name })),
                    customers: value.users.map((item) => ({
                        id: item.userId,
                        value: item.firstName.trim() === '' || item.firstName === '0' ? `ID ${item.userId}` : item.firstName,
                    })),
                };
            },
            //providesTags: (result, error, id) => [{ type: 'Orders', id }],
        }),
        editOrderComment: builder.mutation({
            query: ({ orderId, comment }) => ({
                method: 'POST',
                url: `order/comment`,
                body: { orderId, comment },
            }),
            invalidatesTags: (result, error, { orderId }) => [{ type: 'Orders', orderId }],
        }),
        // TODO: use helper
        exportOrdersToExcel: builder.mutation({
            queryFn: async ({ limit, search, filter }, api, extraOptions, baseQuery) => {
                const result = (await baseQuery({
                    method: 'POST',
                    url: 'order/xslx4Admin',
                    cache: 'no-cache',
                    // body: {
                    // 	page: 1,
                    // 	rowsPerPage: limit,
                    // 	search,
                    // 	startDate: filter.startDate,
                    // 	endDate: filter.endDate,
                    // 	startPrice: filter.startPrice,
                    // 	endPrice: filter.endPrice,
                    // 	startQty: filter.startQty,
                    // 	endQty: filter.endQty,
                    // 	statuses: filter.statuses,
                    // 	customers: filter.customers,
                    // 	suppliers: filter.suppliers,
                    // 	customerManagers: filter.customerManagers,
                    // 	supplierManagers: filter.supplierManagers,
                    // 	logos: filter.logos,
                    // 	orders: filter.orders,
                    // 	brands: filter.brands,
                    // 	articles: filter.articles,
                    // 	sortColumn: null,
                    // 	sortDirectionAsc: false,
                    // } as OrdersApiRequest,
                    body: getOrdersRequestPayload({ page: 1, rowsPerPage: limit, search, filter }),
                    // responseHandler: (response) => response.blob(),
                    responseHandler: blobResponseHandler,
                }));
                if (result.error) {
                    enqueueSnackbar({
                        variant: 'error',
                        header: t('file.download.failed'),
                        body: getErrorFromException(result.error),
                        persist: true,
                    });
                    return { data: null };
                }
                const hiddenElement = document.createElement('a');
                const url = window.URL || window.webkitURL;
                const blob = url.createObjectURL(result.data);
                hiddenElement.href = blob;
                hiddenElement.target = '_blank';
                hiddenElement.download = `${t('file.name.orders')}.xlsx`;
                hiddenElement.click();
                URL.revokeObjectURL(blob);
                return { data: null };
            },
        }),
    }),
});
export const { useGetOrdersQuery, useGetOrderCountQuery, useGetOrderInfoQuery, useGetOrderStatusesQuery, useUpdateOrderStatusMutation, useGetOrderStatusHistoryQuery, useGetSuppliersCustomersLookupsQuery, useEditOrderCommentMutation, useExportOrdersToExcelMutation, } = ordersApi;
