import { jsx as _jsx } from "react/jsx-runtime";
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { styled, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const SnackbarIcon = ({ variant }) => {
    const { palette } = useTheme();
    const size = variant === 'receiptSubmissionSuccess' || variant === 'receiptSubmissionError' ? '2xl' : 'xl';
    return (_jsx(IconContainer, { size: size, children: _jsx(FontAwesomeIcon, { size: size, icon: variant === 'success' || variant === 'receiptSubmissionSuccess'
                ? faCircleCheck
                : variant === 'warning'
                    ? faCircleExclamation // TODO: (later) use regular version
                    : variant === 'error' || variant === 'receiptSubmissionError'
                        ? faCircleXmark
                        : variant === 'info'
                            ? faCircleInfo // TODO: (later) use regular version
                            : faCircleInfo, color: variant === 'receiptSubmissionSuccess' || variant === 'receiptSubmissionError'
                ? palette.common.white
                : variant === 'success'
                    ? palette.success.main
                    : variant === 'warning'
                        ? palette.warning.main
                        : variant === 'error'
                            ? palette.error.dark
                            : variant === 'info'
                                ? palette.info.main
                                : palette.info.main }) }));
};
const IconContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'size' })(({ theme, size }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: size == 'xl' ? '1.5rem' : '2.5rem',
    height: size == 'xl' ? '1.5rem' : '2.5rem',
}));
